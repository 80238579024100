import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
	{
		path: '',
		// redirectTo: '/tabs/home',
		redirectTo: '/intro',
		pathMatch: 'full'
	},
	{
		path: 'tabs',
		loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
	},
	{
		path: 'registration',
		loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationPageModule)
	},
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
	},
	{
		path: 'password-reset',
		loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
	},
	{
		path: 'intro',
		loadChildren: () => import('./intro/intro.module').then(m => m.IntroPageModule)
	},
	{
		path: 'following',
		loadChildren: () => import('./following/following.module').then(m => m.FollowingPageModule)
	},
	{
		path: 'clubs/:clubId',
		loadChildren: () => import('./club/club.module').then(m => m.ClubPageModule)
	},
	{
		path: 'issue/:issueId',
		loadChildren: () => import('./issue/issue.module').then(m => m.IssuePageModule)
	},
	{
		path: 'issues',
		loadChildren: () => import('./my-issues/my-issues.module').then(m => m.MyIssuesPageModule)
	},
	{
		path: 'transaction',
		loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionPageModule)
	},
	{
		path: 'pdf-view',
		loadChildren: () => import('./pdf-view/pdf-view.module').then(m => m.PDFViewPageModule)
	},
	{
		path: 'privacy-policy',
		loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
	},
	{
		path: 'terms-of-use',
		loadChildren: () => import('./terms-of-use/terms-of-use.module').then(m => m.TermsOfUsePageModule)
	},
	{
		path: 'profile-edit',
		loadChildren: () => import('./profile/edit/edit.module').then(m => m.EditPageModule)
	},
	{
		path: 'payments',
		loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsPageModule)
	},
	{
		path: 'add-card',
		loadChildren: () => import('./add-card/add-card.module').then(m => m.AddCardPageModule)
	},
	{
		path: 'payment',
		loadChildren: () => import('./payment/payment.module').then(m => m.PaymentPageModule)
	},
	{
		path: 'login-register-template',
		loadChildren: () => import('./login-register-template/login-register-template.module').then(m => m.LoginRegisterTemplatePageModule)
	},
	{
		path: 'faq',
		loadChildren: () => import('./faq/faq.module').then(m => m.FaqPageModule)
	},
	{
		path: 'guide',
		loadChildren: () => import('./guide/guide.module').then(m => m.GuidePageModule)
	},
	{
		path: 'brands',
		loadChildren: () => import('./brands/brands.module').then(m => m.BrandsPageModule)
	},
	{
		path: 'subscription',
		loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionPageModule)
	},
	{
		path: 'subscriptions',
		loadChildren: () => import('./subscriptions/subscriptions.module').then(m => m.SubscriptionsPageModule)
	},
	{
		path: 'gift',
		loadChildren: () => import('./gift/gift.module').then(m => m.GiftPageModule)
	},  
	{
		path: 'news-details',
		loadChildren: () => import('./news-details/news-details.module').then(m => m.NewsDetailsPageModule)
	  },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
