import { environment } from './environments/environment';
import DEVELOPMENT_CONFIG from "./configs/config.development";
import STAGING_CONFIG from "./configs/config.staging";
import PRODUCTION_CONFIG from "./configs/config.production";

export const CONFIG = (() => {
	console.log('environment', environment.type);
	switch (environment.type){
		case "production": {
			return PRODUCTION_CONFIG;
		}
		case "staging": {
			return STAGING_CONFIG;
		}
		default: {
			return DEVELOPMENT_CONFIG;
		}
	}
})();