import { Component, OnDestroy, OnInit } from '@angular/core'; //ChangeDetectorRef
import { NavController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppService } from '../services/app.service';
// import { InAppPurchase2, IAPProduct } from '@ionic-native/in-app-purchase-2/ngx';
import { OneSignal, OSDisplayType } from '@ionic-native/onesignal/ngx';
import { CONFIG } from '../config';

//import { Deploy } from 'cordova-plugin-ionic/dist/ngx';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	interval;
	// products: IAPProduct[] = [];

	constructor(
		private app: AppService,
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private toastCtrl: ToastController,
		private navCtrl: NavController,
		// private store: InAppPurchase2,
		private oneSignal: OneSignal,
		// private ref: ChangeDetectorRef,
		// private deploy: Deploy
	) {
		this.platform.backButton.subscribeWithPriority(10, () => {
			this.navCtrl.back();
		});
	}

	ngOnInit() {
		// this.app.api.post2("test");
		this.initializeApp().catch(err => console.error(err));
	}

	ngOnDestroy() {
		clearInterval(this.interval);
	}

	runCheckInterval() {
		// this.interval = setInterval(() => {
			(async () => {
				/** Get user */
				const userRes = await this.app.api.get('/user');
				const user = userRes.data.user;
				const oldUser = await this.app.getItem('user', null);

				await this.app.setItem('user', user);

				if (oldUser && !user) {
					await this.app.logout();
					const toast = await this.toastCtrl.create({
						message: 'You have been logged out',
						cssClass: 'toast-style',
						color: 'danger',
						duration: 2000,
					});
					await toast.present();
				}
			})().catch(err => {
				if (err.message == 'Network Error') {
					//console.log("Is's just network error, everything should work back, when internet connection will come back.");
				} else {
					console.error(err);
				}
			});
		// }, 1000);
	}

	async initOneSignal() {
		if (this.platform.is('cordova')) {
			//alert("Init OneSignal");

			await this.oneSignal.startInit(CONFIG.ONESIGNAL_APP_ID);
			await this.oneSignal.handleNotificationReceived().subscribe(data => {
				console.log("received:", data);
			});
			await this.oneSignal.handleNotificationOpened().subscribe(data => {
				console.log("opened:", data);
			});
			await this.oneSignal.iOSSettings({
				kOSSettingsKeyAutoPrompt: true,
				kOSSettingsKeyInAppLaunchURL: false,
			});
			await this.oneSignal.inFocusDisplaying(OSDisplayType.Notification);
			await this.oneSignal.endInit();

			// alert("End OneSignal");
		}
	}

	async initializeApp(){
		try {
			console.log('initializeApp');

			await this.platform.ready();

			// await this.statusBar.styleDefault();
			await this.statusBar.backgroundColorByHexString('#000000');
			await this.statusBar.styleLightContent();

			await this.initOneSignal();

			await this.app.init();

			// console.log(session);

			await this.splashScreen.hide();

			await this.runCheckInterval();

			// in-app purchase 2
			if (this.platform.is('ios')) {
				// debug mode
				// this.store.verbosity = this.store.DEBUG;
				// this.store.error((err) => {
				// 	console.error('Store error');
				// 	console.error(err);
				// });
				//
				// this.store.ready(() => {
				// 	console.log('!!! Store is ready !!!');
				// 	console.log('STORE PRODUCTS: ' + JSON.stringify(this.store.products));
				// 	//this.products = this.store.products;
				// });

				// this.store.refresh();
			}

			/*
			try {
				await this.deploy.configure({
					channel: 'Master'
				});

				const update = await this.deploy.checkForUpdate();
				// console.log("update:", update);
				// alert(JSON.stringify(update, null, 4));

				if (update.available){
					// alert(JSON.stringify(update));
					await this.deploy.downloadUpdate((progress) => {
						console.log(`Downloading... ${progress}%`);
					})
					await this.deploy.extractUpdate((progress) => {
						console.log(`Extracting... ${progress}%`);
					})
				}
			} catch (err) {
				console.error(err);
			}
			*/
		} catch (err) {
			await this.app.showErrorToast(err.message);
			throw err;
		}
	}

}
