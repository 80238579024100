import {Injectable} from '@angular/core';
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import * as localForage from 'localforage';
import {CONFIG} from '../config';
import {IIssue} from '../interfaces/issue';
import {Platform, ToastController} from '@ionic/angular';
import {AppVersion} from '@ionic-native/app-version/ngx';

export const wait = (time = 0) => {
	return new Promise((resolve: any) => {
		setTimeout(() => resolve(), time);
	});
};

interface AxiosInstanceModified extends AxiosInstance {
	postOrigin?<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
}

const api: AxiosInstanceModified = axios.create({
	baseURL: `${CONFIG.SERVER_URL}/api`,
	timeout: 1000 * 60,
	headers: {
		session: localStorage.getItem('session') || '',
		app_version: "",
	}
});

api.postOrigin = api.post;

Object.defineProperty(api, 'post', {
	get: () => {
		return async (url: string, data?: any, config?: AxiosRequestConfig, tries = 5) => {
			const func = async (): Promise<AxiosResponse> => {
				tries--;

				try {
					return (await api.postOrigin(url, data, config));
				} catch (err) {
					if (!tries) {
						throw err;
					}
					if (err.message !== 'Network Error') {
						throw err;
					}

					// console.error(err);
					await wait(1000);
					return (await func());
				}
			};

			return (await func());
		};
	}
});

const db = localForage.createInstance({
	name: 'app',
});

@Injectable()
export class AppService {
	// stripe_key = stripe_key;
	// one_signal_app_id = one_signal_app_id;
	// server_url = server_url;
	currency = '£';
	app_version = '';

	constructor(
		private toastCtrl: ToastController,
		private appVersion: AppVersion,
		private platform: Platform,
	) {
		//
	}

	wait = wait;

	get online() {
		return navigator?.onLine || false;
	}

	get db() {
		return db;
	}

	/** Test Data */
	test_live_brand = {
		_id: 'rwp8Oj7JS',
		name: 'Test',
		price: 0.02,
		issue_price: 0.01,
		issues: 5,
		frequency: 'monthly',
		created: 1603720987580
	};
	test_live_issue = {
		_id: 'lcmGkTQH0',
		name: 'Test',
		status: 'unlisted',
		description: 'test',
		brand: 'rwp8Oj7JS',
		clubs: [],
		mentions: [],
		published: 1603798244490,
		created: 1603798260683
	};

	/** API */
	get api() {
		api.defaults.headers.session = localStorage.getItem('session') || '';
		api.defaults.headers.app_version = this.app_version;
		return api;
	}

	/** Init */
	async init() {
		/** Get app version */
		if (this.platform.is("cordova")) {
			this.app_version = await this.appVersion.getVersionNumber();
		}

		/** Get user */
		const userRes = await this.api.get('/user');
		const user = userRes.data.user;

		// console.log(user);

		await this.setItem('user', user);

		await Promise.all([
			async () => {
				/** Get session */
				const sessionRes = await this.api.get('/session');
				const session = sessionRes.data.session;

				localStorage.setItem('session', session);
			},

			async () => {
				/** Get countries */
				const countriesRes = await this.api.get(`/countries`);
				const countries = countriesRes.data.countries;
				// console.log("countries:", countries);
				await this.setItem('countries', countries);
			},

			async () => {
				/** Get payment methods */
				if (user) {
					const methodsRes = await this.api.get('/payment-methods');
					const methods = methodsRes.data.methods;

					await this.setItem('payment-methods', methods);
				}
			},
		].map(func => func()));
	}

	/** LocalStorage */
	getLocalItem(key, fallback: any = null) {
		const str = localStorage.getItem(key);

		return str ? JSON.parse(str) : fallback;
	}

	setLocalItem(key, value) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	/** LocalForage */
	async getItem(key, fallback: any = null) {
		const str = await this.db.getItem(key) as string;

		return str ? JSON.parse(str) : fallback;
	}

	async setItem(key, value) {
		await this.db.setItem(key, JSON.stringify(value));
	}

	/** Logout */
	async logout() {
		const guide = await this.getItem('guide');

		await this.db.clear();
		await localStorage.clear();

		/** Keep guide status after logout */
		if (guide) {
			await this.setItem('guide', true);
		}
	}

	/** Cache issue */
	async cacheIssue(issue: IIssue) {
		console.log('Cache started');
		// console.time("cache")

		if (issue) {
			let saveIssue = {
				// _id: issue._id,
				...issue,
				status: 'started',
				pages: null,
			};

			try {
				await this.setItem('cached_issue', saveIssue);

				saveIssue.pages = await Promise.all((issue?.converted?.jpeg || []).map(el => {
					return (async (el) => {
						/** Download imgs */
						const originalRes = await axios({
							method: 'GET',
							// url: `https://cors-anywhere.herokuapp.com/${el.original}`,
							url: el.original + `?d=${Date.now()}`,
							responseType: 'blob',
						});

						const smallRes = await axios({
							method: 'GET',
							// url: `https://cors-anywhere.herokuapp.com/${el.small}`,
							url: el.small + `?d=${Date.now()}`,
							responseType: 'blob',
						});

						/** Base64 convert */
						const base64Convert = async (convertData) => {
							return new Promise((resolve, reject) => {
								const reader = new window.FileReader();
								reader.readAsDataURL(convertData);
								reader.onload = () => {
									resolve(reader.result);
								};
							});
						};

						/** Push converted page to tab */
						return {
							original: await base64Convert(originalRes.data),
							small: await base64Convert(smallRes.data),
						};
					})(el);
				}));
				saveIssue.status = 'finished';

				/** Save parsed and converted issue to local */
				await this.setItem('cached_issue', saveIssue);

				console.log('Issue saved to cache');
			} catch (err) {
				// console.error("Caching error:", err);
				saveIssue.status = 'error';
				await this.setItem('cached_issue', saveIssue);
				throw err;
			}
		} else {
			console.warn("Issue is null");
		}
	}

	async showErrorToast(message: string) {
		/** Show toast */
		const toast = await this.toastCtrl.create({
			message: message,
			duration: 6000,
			cssClass: 'toast-style',
			color: 'danger'
		});
		await toast.present();
	}
}
