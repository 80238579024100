import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from '../services/app.service';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Device } from '@ionic-native/device/ngx';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { OneSignal } from '@ionic-native/onesignal/ngx';
// import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [BrowserModule,NgxYoutubePlayerModule, IonicModule.forRoot(), AppRoutingModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
	providers: [
		AppVersion,
		StatusBar,
		SplashScreen,
		{
			provide: RouteReuseStrategy,
			useClass: IonicRouteStrategy
		},
		AppService,
		NavParams,
		FileOpener,
		File,
		InAppBrowser,
		OneSignal,
		Device,
		InAppPurchase2,
		//Deploy
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	//
}